<template>
  <div v-if="hasPerm('schools.view_school')">
    <page-header title="Écoles" icon="fa fa-school" :links="getLinks()"></page-header>
    <b-row>
      <b-col cols="4" class="border-right">
        <loading-gif :loading-name="schoolsLoading"></loading-gif>
        <div v-if="!isLoading(schoolsLoading)">
          <b-row>
            <b-col>
              <h3>Écoles</h3>
            </b-col>
            <b-col cols="2" class="text-right">
              <add-school-modal
                @done="reloadSchool()"
              ></add-school-modal>
            </b-col>
          </b-row>
          <div
            v-for="school in schools"
            :key="school.id"
            class="row-line"
            :class="isSchoolSelected(school) ? 'selected-line' : ''"
          >
            <b-row>
              <b-col>
                <div @click="selectSchool(school)">{{school.name}}</div>
              </b-col>
              <b-col v-if="isSchoolSelected(school)" cols="2" class="text-right">
                <delete-school-modal
                  :school="school"
                  @done="reloadSchool()"
                ></delete-school-modal>
              </b-col>
            </b-row>
          </div>
          <div class="row-line" :class="undefinedSchoolClass ? 'selected-line' : ''">
            <div @click="selectUndefinedSchoolClass()">École à définir</div>
          </div>
        </div>
      </b-col>
      <b-col cols="4" class="border-right">
        <div v-if="selectedSchool || undefinedSchoolClass" >
          <b-row v-if="selectedSchool">
            <b-col>
              <h3>{{ selectedSchool.name }}</h3>
            </b-col>
            <b-col  cols="2" class="text-right" v-if="hasPerm('schools.add_schoolclass')">
              <a href @click.prevent="onAddClass" class="btn btn-secondary btn-sm">
                <i class="fa fa-plus"></i>
              </a>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <h3>École à définir</h3>
            </b-col>
          </b-row>
          <div v-if="selectedSchool">
            <div v-if="selectedSchool.schoolClasses.length === 0" class="empty-text">
              Aucune classe pour cette école
            </div>
            <div
              v-for="schoolClass in selectedSchool.schoolClasses"
              :key="schoolClass.id"
              class="row-line"
              :class="isSchoolClassSelected(schoolClass) ? 'selected-line' : ''"
            >
              <b-row>
                <b-col>
                  <div @click="selectSchoolClass(schoolClass)">
                    <span :style="schoolClassStyle(schoolClass)">{{ schoolClass.name }}</span>
                  </div>
                </b-col>
                <b-col
                  v-if="isSchoolClassSelected(schoolClass)"
                  cols="4"
                  class="text-right"
                >
                  <span
                    v-if="hasPerm('schools.change_schoolclass')"
                  >
                    <a href @click.prevent="onEditClass" class="btn btn-secondary btn-sm">
                      <i class="fa fa-edit"></i>
                    </a>
                  </span>
                  &nbsp;
                  <span
                    v-if="hasPerm('schools.delete_schoolclass')"
                  >
                    <delete-school-class-modal
                      :schoolClass="schoolClass"
                      :school="selectedSchool"
                      @done="reloadClass()"
                    ></delete-school-class-modal>
                  </span>
                </b-col>
              </b-row>
            </div>
          </div>
          <div v-else>
            <div class="row-line selected-line">
              Classe à définir
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="4" v-if="selectedSchoolClass || undefinedSchoolClass">
        <loading-gif :loading-name="studentsLoading"></loading-gif>
        <div v-if="!isLoading(studentsLoading)" ref="printMe">
          <b-row>
            <b-col cols="8">
              <div class="no-print">
                <h3 v-if="selectedSchoolClass">{{selectedSchoolClass.name}}</h3>
                <h3 v-else>Classe à définir</h3>
              </div>
              <div class="hide-here">
                <b>{{ fullClassName }}</b>
              </div>
            </b-col>
            <b-col cols="4" class="text-right">
              <counter-label :counter="students.length" label="élève"></counter-label>
            </b-col>
          </b-row>
          <div v-if="students.length === 0" class="empty-text">
            Aucun élève dans cette classe
          </div>
          <div v-else class="no-print">
            <b-row v-if="canEdit">
              <b-col>
                <a href class="btn btn-secondary btn-xs" @click.prevent="selectedStudents = students">
                  Tout sélectionner
                </a>
              </b-col>
              <b-col class="text-right">
                <a href class="btn btn-secondary btn-xs" @click.prevent="selectedStudents = []">
                  Tout désélectionner
                </a>
              </b-col>
            </b-row>
          </div>
          <table class="table small-text">
            <tr v-for="student in students" :key="student.id">
              <td style="width: 20px;">
                <b-form-checkbox
                  :id="'student'+student.id"
                  :value=student
                  v-model="selectedStudents"
                  :unchecked-value="false"
                  :disabled="!canEdit"
                ></b-form-checkbox>
              </td>
              <td>{{student.individual.lastName}}</td>
              <td>{{student.individual.firstName}}</td>
              <td>
                <span class="badge" :style="badgeStyle(student)" @click.prevent="selectAllSameYear(student)">
                  {{student.individual.birthDateAsString()}}
                </span>
              </td>
              <td>
                <span class="small-text" v-if="student.lastChange">
                  dernier changement {{ student.lastChange|dateToString() }}
                </span>
              </td>
            </tr>
          </table>
        </div>
        <move-class-students-modal
          :students="selectedStudents"
          :school="selectedSchool"
          :schools="schools"
          :schoolClass="selectedSchoolClass"
          @done="refresh"
        >
        </move-class-students-modal>
      </b-col>
    </b-row>
    <edit-school-class-modal
      :school="selectedSchool"
      :school-class="selectedSchoolClass"
      modal-id="bv-edit-school-class"
      @done="reloadClass()"
    ></edit-school-class-modal>
  </div>
</template>

<script>
import moment from 'moment'
import MoveClassStudentsModal from '@/components/School/MoveClassStudentsModal'
import AddSchoolModal from '@/components/School/AddSchoolModal'
import DeleteSchoolModal from '@/components/School/DeleteSchoolModal'
import DeleteSchoolClassModal from '@/components/School/DeleteSchoolClassModal'
import EditSchoolClassModal from '@/components/School/EditSchoolClassModal'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import CounterLabel from '@/components/Controls/CounterLabel'
import { BackendMixin } from '@/mixins/backend'
import { makeSchool } from '@/types/schools'
import { makeStudent } from '@/types/people'
import { existsIn } from '@/utils/arrays'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'

export default {
  name: 'schools-list',
  components: {
    DeleteSchoolClassModal,
    EditSchoolClassModal,
    DeleteSchoolModal,
    AddSchoolModal,
    CounterLabel,
    PageHeader,
    MoveClassStudentsModal,
    LoadingGif,
  },
  mixins: [BackendMixin],
  data() {
    return {
      schoolsLoading: 'ecoles',
      selectedSchool: null,
      selectedSchoolClass: null,
      studentsLoading: 'eleves',
      schools: [],
      students: [],
      selectedStudents: [],
      undefinedSchoolClass: false,
    }
  },
  async mounted() {
    await this.loadSchools()
    this.selectOneSchoolByDefault()
  },
  computed: {
    canEdit() {
      return this.hasPerm('schools.change_schoolclass')
    },
    fullClassName() {
      if (this.selectedSchool) {
        if (this.selectedSchoolClass) {
          return this.selectedSchool.name + ' ' + this.selectedSchoolClass.name
        } else {
          return this.selectedSchool.name + ' Classe à définir'
        }
      } else {
        return 'École à définir'
      }
    },
  },
  methods: {
    async loadSchools() {
      let url = '/api/schools/schools/'
      this.startLoading(this.schoolsLoading)
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.schools = resp.data.map(elt => makeSchool(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.schoolsLoading)
    },
    showMoveModal() {
      if (this.selectedStudents) {
        this.$bvModal.show('bv-modal-move-school-class')
      }
    },
    getLinks() {
      const links = [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
      if (this.hasPerm('schools.change_schoolclass')) {
        links.push(
          {
            id: 3,
            label: 'Changement de classe',
            callback: this.showMoveModal,
            icon: 'fa fa-school',
            cssClass: (this.selectedStudents.length === 0) ? 'btn-primary disabled' : 'btn-primary',
          }
        )
      }
      return links
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = slugify(this.fullClassName)
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent, 'dossier_caf')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = slugify(this.fullClassName)
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    isSchoolSelected(school) {
      return (this.selectedSchool && (school.id === this.selectedSchool.id))
    },
    isSchoolClassSelected(schoolClass) {
      return (this.selectedSchoolClass && (schoolClass.id === this.selectedSchoolClass.id))
    },
    async loadStudents() {
      this.startLoading(this.studentsLoading)
      if (this.selectedSchoolClass) {
        let url = '/api/school/students/' + this.selectedSchoolClass.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.students = resp.data.map(elt => makeStudent(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.studentsLoading)
      } else {
        let url = '/api/school/students/' + 0 + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.students = resp.data.map(elt => makeStudent(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.studentsLoading)
      }
    },
    selectOneSchoolByDefault() {
      if (this.schools.length === 1) {
        this.selectedSchool = this.schools[0]
      }
    },
    reloadSchool() {
      this.students = []
      this.selectedSchoolClass = null
      this.loadSchools()
    },
    async reloadClass() {
      await this.loadSchools()
      for (let school of this.schools) {
        if (school.id === this.selectedSchool.id) {
          this.selectedSchool = school
          this.selectSchool(this.selectedSchool)
        }
      }
    },
    selectSchool(school) {
      this.students = []
      this.selectedStudents = []
      this.undefinedSchoolClass = false
      this.selectedSchool = school
      this.selectedSchoolClass = null
      if (this.selectedSchool.schoolClasses.length === 1) {
        this.selectSchoolClass(this.selectedSchool.schoolClasses[0])
      }
    },
    selectUndefinedSchoolClass() {
      this.undefinedSchoolClass = true
      this.selectedSchool = null
      this.selectedSchoolClass = null
      this.students = []
      this.selectedStudents = []
      this.loadStudents()
    },
    selectSchoolClass(schoolClass) {
      this.students = []
      this.selectedStudents = []
      this.selectedSchoolClass = schoolClass
      this.loadStudents()
    },
    refresh() {
      this.students = []
      this.selectedStudents = []
      if (this.selectedSchoolClass) {
        this.loadStudents()
      } else if (this.undefinedSchoolClass) {
        this.loadStudents()
      }
    },
    selectAllSameYear(student) {
      if (this.canEdit && student.individual.birthDate) {
        const selectedIds = this.selectedStudents.map(elt => elt.id)
        const year = moment(student.individual.birthDate).year()
        for (let otherStudent of this.students) {
          if (otherStudent.individual.birthDate) {
            const otherYear = moment(otherStudent.individual.birthDate).year()
            if (otherYear === year) {
              if (!existsIn([otherStudent.id], selectedIds)) {
                this.selectedStudents.push(otherStudent)
              }
            }
          }
        }
      }
    },
    onAddClass() {
      this.selectedSchoolClass = null
      this.$bvModal.show('bv-edit-school-class')
    },
    onEditClass() {
      this.$bvModal.show('bv-edit-school-class')
    },
    schoolClassStyle(schoolClass) {
      const style = {
        padding: '1px 8px',
        'border-radius': '4px',
      }
      if (schoolClass.backgroundColor) {
        style['background-color'] = schoolClass.backgroundColor
      }
      if (schoolClass.textColor) {
        style['color'] = schoolClass.textColor
      }
      return style
    },
    badgeStyle(student) {
      let background = '#222'
      let color = '#fff'
      if (student.individual.birthDate) {
        const colors = ['#998eff', '#fb8eff', '#ff8b8b', '#8df6ff', '#bffab6', '#fbff8d', '#ffc08d']
        const year = moment(student.individual.birthDate).year()
        const colorIndex = year % colors.length
        background = colors[colorIndex]
        color = '#222'
      }
      return {
        'background-color': background,
        'color': color,
      }
    },
  },
}

</script>

<style scoped>
.row-line {
  cursor: pointer;
}
.selected-line {
  background: #aaa !important;
  color: #222;
}
.border-right{
  border-right: grey 1px solid;
}
</style>
