<template>
  <span class="change-school-class-button" v-if="hasPerm('schools.change_schoolclass')">
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      @ok.prevent="onSave"
      :ok-disabled="formInvalid"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Changer"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-school"></i> Changer les élèves de classe</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div>
        <b>Les enfants suivants vont changer de classe: </b>
      </div>
      <div style="padding: 5px; border: solid 1px #ccc; margin-bottom: 20px">
        <div v-for="student in students" :key="student.id" class="student-badge">
          {{student.individual.firstName}} {{student.individual.lastName}}
        </div>
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="schools-group"
            label="Écoles"
            label-for="schools"
          >
            <b-form-select
              v-model="selectedSchool"
              id="schools"
            >
            <b-form-select-option
                :value="school"
                v-for="school in schools"
                :key="school.id"
              >
              {{school.name}}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="classes-group"
            label="Classes"
            label-for="classes"
          >
            <b-form-select
              v-model="selectedClass"
              id="classes"
            >
            <b-form-select-option
                :value="schoolClass"
                v-for="schoolClass in selectedSchool.schoolClasses"
                :key="schoolClass.id"
                :disabled="checkClassInProgress(schoolClass)"
              >
              {{schoolClass.name}}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import { getRandomId } from '@/utils/random'
import { mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'

export default {
  name: 'MoveClassStudentsModal',
  mixins: [BackendMixin],
  props: {
    students: Array,
    school: Object,
    schools: Array,
    schoolClass: Object,
  },
  data() {
    return {
      errorText: '',
      selectedSchool: [],
      selectedClass: [],
    }
  },
  mounted() {
    if (this.school) {
      this.selectedSchool = this.school
    }
  },
  computed: {
    formInvalid() {
      return (this.selectedClass.length === 0) || this.isLoading('move-school-class')
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    checkClassInProgress(classInList) {
      return classInList === this.schoolClass
    },
    getId() {
      return 'bv-modal-move-school-class'
    },
    async onSave() {
      this.startLoading('move-school-class')
      let url = '/api/school/change/students-class/'
      const backendApi = new BackendApi('post', url)
      try {
        const data = {
          'students': this.students.map(elt => elt.id),
          'school_class': this.selectedClass.id,
        }
        await backendApi.callApi(data)
        await this.addSuccess('Les enfants ont été changés de classe')
        this.$bvModal.hide(this.getId())
        this.$emit('done', this.selectedClass, this.selectedSchool)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading('move-school-class')
    },
  },
}
</script>

<style scoped>
.student-badge{
  display: inline;
  border: 1px #888 solid;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 16px;
  margin: 2px;
}
.flex-wrap{
  display: flex;
  flex-wrap: wrap;
}
</style>
